export const headerFixed = () => {
  // When user scroll the page, fulfill scroll function
  window.addEventListener(
    "scroll",
    () => {
      scrollGo();
    },
    { passive: true }
  );

  // Getting header
  let header = document?.querySelector(".header");
  let burger = document?.querySelector(".burger");
  let wrapper = document?.querySelector(".wrapper");

  // Adding class "sticky" to the header, when you rich the position.
  // Deleting "sticky"
  function scrollGo() {
    // Get the offset of the header
    let sticky = header.offsetTop;
    if (window.pageYOffset > sticky) {
      header.classList.add("header--fixed");
      burger.classList.add("burger--fixed");
      const headerHeight = document?.querySelector(".header").offsetHeight;

      wrapper.style.paddingTop = `${headerHeight}px`;
    } else {
      header.classList.remove("header--fixed");
      burger.classList.remove("burger--fixed");
      wrapper.style.paddingTop = `0px`;
    }
  }

  if (header) {
    scrollGo();
  }
};
