export const observer = () => {
  const elements = document.querySelectorAll(".hidden");
  const elements2 = document.querySelectorAll(".inactive");

  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };

  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("visible");
        entry.target.classList.remove("hidden");
        observer.unobserve(entry.target);

        const selector = document.querySelector(".start-animation");
        if (selector) {
          if (selector.classList.contains("visible")) {
            selector.classList.add("animation-main");
          }
        }
      }
    });
  }, observerOptions);

  elements.forEach((element) => {
    observer.observe(element);
  });

  const observer2 = new IntersectionObserver((entries2, observer2) => {
    entries2.forEach((entry2) => {
      if (entry2.isIntersecting) {
        entry2.target.classList.add("active");
        entry2.target.classList.remove("inactive");
        observer2.unobserve(entry2.target);
      }
    });
  }, observerOptions);

  elements2.forEach((element) => {
    observer2.observe(element);
  });
};
