export const videoInit = () => {
  const preview = document?.querySelector("[data-download-video-img]");
  const previewImg = document?.querySelector("[data-download-video-img] img");

  if (preview) {
    preview.addEventListener("click", () => {
      previewImg.style.opacity = 0;

      setTimeout(() => {
        preview.style.visibility = "hidden";
      }, 300);
    });
  }
};
