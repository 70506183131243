export const quiz = () => {
  const fake = document?.querySelector(".fake");
  const fakeBtn = document?.querySelector(".quiz-d-none-1");
  const fake2 = document?.querySelector(".fake2");
  const fake3 = document?.querySelector(".fake3");
  const fakeBtn2 = document?.querySelector(".quiz-d-none-2");

  const fake4 = document?.querySelector(".fake4");

  let formValues = {};

  let currentQuestion = 1;
  const totalQuestions = document
    ?.querySelector("[data-quiz-total]")
    ?.getAttribute("data-quiz-total");
  const progressBar = document?.querySelector(".quiz-progress__bar-percentage");

  function quizInit() {
    if (!progressBar) return;
    const progressNumber = document.querySelector(".step-id");

    if (document.querySelector(".quiz__steps_big")) {
      document.getElementById("nextBtn").addEventListener("click", () => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        if (currentQuestion < totalQuestions) {
          document
            .getElementById(`step-${currentQuestion}`)
            .classList.remove("active");
          currentQuestion++;
          progressNumber.textContent = currentQuestion - 1;
          switch (currentQuestion) {
            case 1:
              progressBar.style.width = "00%";
              fake.style.display = "flex";
              fakeBtn.style.display = "none";
              fake2.style.display = "none";

              break;
            case 2:
              progressBar.style.width = "10%";
              fake.style.display = "none";
              fakeBtn.style.display = "flex";
              fake2.style.display = "none";

              break;
            case 3:
              progressBar.style.width = "20%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 4:
              progressBar.style.width = "30%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 5:
              progressBar.style.width = "40%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 6:
              progressBar.style.width = "50%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 7:
              progressBar.style.width = "60%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 8:
              progressBar.style.width = "70%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 9:
              progressBar.style.width = "80%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 10:
              progressBar.style.width = "90%";
              fake.style.display = "none";
              fake2.style.display = "flex";

              break;
            default:
          }

          document
            .getElementById(`step-${currentQuestion}`)
            .classList.add("active");
        }
      });

      document.getElementById("prevBtn").addEventListener("click", () => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        if (currentQuestion > 1) {
          document
            .getElementById(`step-${currentQuestion}`)
            .classList.remove("active");
          currentQuestion--;
          progressNumber.textContent = currentQuestion - 1;

          switch (currentQuestion) {
            case 1:
              progressBar.style.width = "00%";
              fake.style.display = "flex";
              fakeBtn.style.display = "none";
              fake2.style.display = "none";

              break;
            case 2:
              progressBar.style.width = "10%";
              fake.style.display = "none";
              fakeBtn.style.display = "flex";
              fake2.style.display = "none";

              break;
            case 3:
              progressBar.style.width = "20%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 4:
              progressBar.style.width = "30%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 5:
              progressBar.style.width = "40%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 6:
              progressBar.style.width = "50%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 7:
              progressBar.style.width = "60%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 8:
              progressBar.style.width = "70%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 9:
              progressBar.style.width = "80%";
              fake.style.display = "none";
              fake2.style.display = "none";

              break;
            case 10:
              progressBar.style.width = "90%";
              fake.style.display = "none";
              fake2.style.display = "flex";

              break;
            default:
          }
          document
            .getElementById(`step-${currentQuestion}`)
            .classList.add("active");
        }
      });
    }

    if (document.querySelector(".quiz__steps_small")) {
      const nextBtn = document.getElementById("nextBtn2");
      const prevBtn = document.getElementById("prevBtn2");

      // Обработчик для кнопки "Next"
      nextBtn.addEventListener("click", () => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        if (currentQuestion < totalQuestions) {
          // Удаляем активный класс у текущего шага
          document
            .getElementById(`step-${currentQuestion}`)
            .classList.remove("active");

          // Увеличиваем счетчик вопросов
          currentQuestion++;

          // Обновляем прогресс
          progressNumber.textContent = currentQuestion - 1;
          updateProgressBar(currentQuestion);

          // Добавляем активный класс к новому текущему шагу
          document
            .getElementById(`step-${currentQuestion}`)
            .classList.add("active");
        }
      });

      // Обработчик для кнопки "Previous"
      prevBtn.addEventListener("click", () => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        if (currentQuestion > 1) {
          // Удаляем активный класс у текущего шага
          document
            .getElementById(`step-${currentQuestion}`)
            .classList.remove("active");

          // Уменьшаем счетчик вопросов
          currentQuestion--;

          // Обновляем прогресс
          progressNumber.textContent = currentQuestion - 1;
          updateProgressBar(currentQuestion);

          // Добавляем активный класс к новому текущему шагу
          document
            .getElementById(`step-${currentQuestion}`)
            .classList.add("active");
        }
      });

      // Функция обновления прогресс-бара и видимости элементов
      function updateProgressBar(step) {
        switch (step) {
          case 1:
            progressBar.style.width = "0%";
            fake3.style.display = "flex";
            fakeBtn2.style.display = "none";
            fake4.style.display = "none";
            break;
          case 2:
            progressBar.style.width = "33%";
            fake3.style.display = "none";
            fakeBtn2.style.display = "flex";
            fake4.style.display = "none";
            break;
          case 3:
            progressBar.style.width = "66%";
            fake3.style.display = "none";
            fake4.style.display = "flex";
            break;
          default:
            break;
        }
      }
    }
  }
  quizInit();

  //selected items
  function selectChoices() {
    if (document.querySelectorAll(".single-choice")) {
      document.querySelectorAll(".single-choice").forEach((wrapper) => {
        wrapper.addEventListener("click", (event) => {
          if (event.target.classList.contains("single-choice__item")) {
            // Удаляем класс 'active' у всех элементов .single-choice__item в текущей обертке .single-choice
            wrapper.querySelectorAll(".single-choice__item").forEach((item) => {
              item.classList.remove("active");
            });

            // Добавляем класс 'active' к нажатому элементу
            event.target.classList.add("active");
          }
        });
      });
      // Находим все элементы с классом .multy-choice__item
      const multyChoiceItems = document.querySelectorAll(".multy-choice__item");

      // Добавляем обработчик события click для каждого элемента
      multyChoiceItems.forEach((item) => {
        item.addEventListener("click", (event) => {
          // Получаем вложенный input элемент
          const input = item.querySelector("input");

          // Изменяем активный класс в зависимости от состояния input
          if (input.checked) {
            item.classList.add("active");
          } else {
            item.classList.remove("active");
          }
        });
      });
    }
  }
  selectChoices();

  function copyText() {
    const parentItems = document.querySelectorAll(".advice__item");

    if (!parentItems) return;
    parentItems.forEach((item) => {
      const copyBtn = item.querySelector(".advice__copy");
      const copyTextWrap = item.querySelector(".advice__text");
      const copyBtnText = item.querySelector(".advice__copy span");

      copyBtn.addEventListener("click", () => {
        const range = document.createRange();
        range.selectNode(copyTextWrap);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);

        try {
          document.execCommand("copy");

          const copyBtnTextAll =
            document.querySelectorAll(".advice__copy span");
          copyBtnTextAll.forEach((item) => {
            item.textContent = "Copy";
          });
          copyBtnText.textContent = "Copied";
        } catch (err) {
          console.error("Failed to copy text", err);
        }

        window.getSelection().removeAllRanges(); // Убираем выделение
      });
    });
  }

  function regeneration() {
    const regenerateWrappers = document?.querySelectorAll(".advice__item");

    regenerateWrappers.forEach((item) => {
      const regenerateBtn = item.querySelector(".advice__regenerate");
      const regenerateText = item.querySelector(".advice__text");

      regenerateBtn.addEventListener("click", () => {
        const text = regenerateText.innerText;

        regenerateBtn.classList.add("spin");

        fetch("https://us-central1-sci-match.cloudfunctions.net/paraphrase", {
          method: "POST",
          body: JSON.stringify({ message: text }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            const new_text = data.new_text;
            regenerateText.innerText = new_text;

            regenerateBtn.classList.remove("spin");
          });
      });
    });
  }

  //fetch
  if (fake2) {
    fake2.addEventListener("click", (e) => {
      e.preventDefault();

      const form = document.getElementById("quiz__test");
      const formData = new FormData(form);
      render_progress_submit();

      formData.forEach((value, key) => {
        if (formValues[key]) {
          if (!Array.isArray(formValues[key])) {
            formValues[key] = [formValues[key]];
          }
          formValues[key].push(value);
        } else {
          formValues[key] = value;
        }
      });

      console.log(formValues);

      const bodyWrapper = document?.querySelector(".wrapper main");
      // bodyWrapper.innerHTML = `
      //       <div class="wait-quiz z-content">
      //         <div class="wait-quiz__block">
      //           Please wait
      //           <span class="loader-quiz"></span>
      //         </div>
      //       </div>
      //       `;

      function postJSON(data) {
        fetch("https://us-central1-sci-match.cloudfunctions.net/create_bio", {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);

            // Функция для создания HTML-кода
            function createHTML(data) {
              // Заголовок секции
              let html = `
              <section class="advice">
                <div class="container">
                  <div class="advice__wrapper z-content">
                    <h1 class="advice__title">Here’s everything to help you shine!</h1>
                    <div class="advice__block">`;

              // Добавляем каждый элемент из данных
              for (const [key, value] of Object.entries(data)) {
                if (key == "bio") {
                  html += `
                      <div class="advice__item advice__item_order">
                        <div class="advice__text">${key}: ${value}</div>
                        <div class="advice__wrap">
                          <button type="button" class="advice__copy">
                            <svg
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_245_1666)">
                                <path
                                  d="M10.8337 17.1667C11.9383 17.1654 12.9974 16.7259 13.7785 15.9448C14.5596 15.1637 14.999 14.1047 15.0003 13V5.70251C15.0016 5.26448 14.916 4.83055 14.7483 4.42586C14.5807 4.02118 14.3343 3.6538 14.0237 3.34501L12.1553 1.47668C11.8465 1.16599 11.4792 0.919683 11.0745 0.752031C10.6698 0.584379 10.2359 0.498718 9.79783 0.500014H5.83366C4.729 0.501338 3.66996 0.940749 2.88884 1.72186C2.10773 2.50298 1.66832 3.56202 1.66699 4.66668V13C1.66832 14.1047 2.10773 15.1637 2.88884 15.9448C3.66996 16.7259 4.729 17.1654 5.83366 17.1667H10.8337ZM3.33366 13V4.66668C3.33366 4.00364 3.59705 3.36776 4.06589 2.89891C4.53473 2.43007 5.17062 2.16668 5.83366 2.16668C5.83366 2.16668 9.93283 2.17835 10.0003 2.18668V3.83335C10.0003 4.27538 10.1759 4.6993 10.4885 5.01186C10.801 5.32442 11.225 5.50001 11.667 5.50001H13.3137C13.322 5.56751 13.3337 13 13.3337 13C13.3337 13.6631 13.0703 14.2989 12.6014 14.7678C12.1326 15.2366 11.4967 15.5 10.8337 15.5H5.83366C5.17062 15.5 4.53473 15.2366 4.06589 14.7678C3.59705 14.2989 3.33366 13.6631 3.33366 13ZM18.3337 7.16668V16.3333C18.3323 17.438 17.8929 18.497 17.1118 19.2782C16.3307 20.0593 15.2717 20.4987 14.167 20.5H6.66699C6.44598 20.5 6.23402 20.4122 6.07774 20.2559C5.92146 20.0997 5.83366 19.8877 5.83366 19.6667C5.83366 19.4457 5.92146 19.2337 6.07774 19.0774C6.23402 18.9211 6.44598 18.8333 6.66699 18.8333H14.167C14.83 18.8333 15.4659 18.57 15.9348 18.1011C16.4036 17.6323 16.667 16.9964 16.667 16.3333V7.16668C16.667 6.94567 16.7548 6.73371 16.9111 6.57743C17.0674 6.42114 17.2793 6.33335 17.5003 6.33335C17.7213 6.33335 17.9333 6.42114 18.0896 6.57743C18.2459 6.73371 18.3337 6.94567 18.3337 7.16668Z"
                                  fill="#02102B"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_245_1666">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="translate(0 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>copy</span>
                          </button>
                          <button type="button" class="advice__regenerate">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_245_1668)">
                                <path
                                  d="M10 1.6669C11.1054 1.67052 12.1992 1.89335 13.2179 2.32247C14.2366 2.75159 15.1602 3.37849 15.935 4.1669H13.3333C13.1123 4.1669 12.9004 4.25469 12.7441 4.41097C12.5878 4.56726 12.5 4.77922 12.5 5.00023C12.5 5.22124 12.5878 5.43321 12.7441 5.58949C12.9004 5.74577 13.1123 5.83356 13.3333 5.83356H16.7858C17.1962 5.83334 17.5897 5.67023 17.8798 5.38007C18.17 5.0899 18.3331 4.69642 18.3333 4.28606V0.833563C18.3333 0.61255 18.2455 0.400588 18.0893 0.244308C17.933 0.0880274 17.721 0.00022997 17.5 0.00022997C17.279 0.00022997 17.067 0.0880274 16.9107 0.244308C16.7545 0.400588 16.6667 0.61255 16.6667 0.833563V2.56523C15.2893 1.32477 13.5938 0.492238 11.7701 0.160854C9.94635 -0.17053 8.06636 0.0123233 6.34066 0.688936C4.61496 1.36555 3.11169 2.50921 1.99918 3.99183C0.886679 5.47446 0.208874 7.23752 0.0416727 9.08356C0.0309088 9.19961 0.0444358 9.31663 0.0813918 9.42716C0.118348 9.53769 0.177922 9.63931 0.25632 9.72555C0.334717 9.81179 0.430218 9.88075 0.536738 9.92804C0.643258 9.97533 0.75846 9.99992 0.875006 10.0002C1.07883 10.0028 1.27628 9.92926 1.42872 9.79393C1.58116 9.65861 1.6776 9.47126 1.69917 9.26856C1.88468 7.19419 2.83915 5.26415 4.37507 3.8576C5.911 2.45104 7.91735 1.66964 10 1.6669Z"
                                  fill="#02102B"
                                />
                                <path
                                  d="M19.1262 10.0001C18.9223 9.99747 18.7249 10.071 18.5724 10.2064C18.42 10.3417 18.3236 10.529 18.302 10.7317C18.1642 12.3178 17.5744 13.831 16.6025 15.0919C15.6306 16.3528 14.3175 17.3085 12.8188 17.8455C11.3201 18.3826 9.69882 18.4785 8.14728 18.1218C6.59575 17.7652 5.17907 16.971 4.06533 15.8334H6.66699C6.88801 15.8334 7.09997 15.7456 7.25625 15.5893C7.41253 15.4331 7.50033 15.2211 7.50033 15.0001C7.50033 14.7791 7.41253 14.5671 7.25625 14.4108C7.09997 14.2545 6.88801 14.1667 6.66699 14.1667H3.21449C3.01124 14.1666 2.80996 14.2066 2.62216 14.2843C2.43436 14.362 2.26372 14.476 2.12 14.6198C1.97628 14.7635 1.8623 14.9341 1.78457 15.1219C1.70684 15.3097 1.66688 15.511 1.66699 15.7142V19.1668C1.66699 19.3878 1.75479 19.5997 1.91107 19.756C2.06735 19.9123 2.27931 20.0001 2.50033 20.0001C2.72134 20.0001 2.9333 19.9123 3.08958 19.756C3.24586 19.5997 3.33366 19.3878 3.33366 19.1668V17.4351C4.71102 18.6755 6.4065 19.5081 8.23024 19.8395C10.054 20.1709 11.934 19.988 13.6597 19.3114C15.3854 18.6348 16.8886 17.4911 18.0011 16.0085C19.1137 14.5258 19.7915 12.7628 19.9587 10.9167C19.9694 10.8007 19.9559 10.6837 19.9189 10.5731C19.882 10.4626 19.8224 10.361 19.744 10.2747C19.6656 10.1885 19.5701 10.1195 19.4636 10.0723C19.3571 10.025 19.2419 10.0004 19.1253 10.0001H19.1262Z"
                                  fill="#02102B"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_245_1668">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        </div>
                      </div>`;
                }

                if (key !== "bio") {
                  html += `
                  <div class="advice__item">
                    <div class="advice__text">${key}: ${value}</div>
                    <div class="advice__wrap">
                      <button type="button" class="advice__copy">
                        <svg
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_245_1666)">
                            <path
                              d="M10.8337 17.1667C11.9383 17.1654 12.9974 16.7259 13.7785 15.9448C14.5596 15.1637 14.999 14.1047 15.0003 13V5.70251C15.0016 5.26448 14.916 4.83055 14.7483 4.42586C14.5807 4.02118 14.3343 3.6538 14.0237 3.34501L12.1553 1.47668C11.8465 1.16599 11.4792 0.919683 11.0745 0.752031C10.6698 0.584379 10.2359 0.498718 9.79783 0.500014H5.83366C4.729 0.501338 3.66996 0.940749 2.88884 1.72186C2.10773 2.50298 1.66832 3.56202 1.66699 4.66668V13C1.66832 14.1047 2.10773 15.1637 2.88884 15.9448C3.66996 16.7259 4.729 17.1654 5.83366 17.1667H10.8337ZM3.33366 13V4.66668C3.33366 4.00364 3.59705 3.36776 4.06589 2.89891C4.53473 2.43007 5.17062 2.16668 5.83366 2.16668C5.83366 2.16668 9.93283 2.17835 10.0003 2.18668V3.83335C10.0003 4.27538 10.1759 4.6993 10.4885 5.01186C10.801 5.32442 11.225 5.50001 11.667 5.50001H13.3137C13.322 5.56751 13.3337 13 13.3337 13C13.3337 13.6631 13.0703 14.2989 12.6014 14.7678C12.1326 15.2366 11.4967 15.5 10.8337 15.5H5.83366C5.17062 15.5 4.53473 15.2366 4.06589 14.7678C3.59705 14.2989 3.33366 13.6631 3.33366 13ZM18.3337 7.16668V16.3333C18.3323 17.438 17.8929 18.497 17.1118 19.2782C16.3307 20.0593 15.2717 20.4987 14.167 20.5H6.66699C6.44598 20.5 6.23402 20.4122 6.07774 20.2559C5.92146 20.0997 5.83366 19.8877 5.83366 19.6667C5.83366 19.4457 5.92146 19.2337 6.07774 19.0774C6.23402 18.9211 6.44598 18.8333 6.66699 18.8333H14.167C14.83 18.8333 15.4659 18.57 15.9348 18.1011C16.4036 17.6323 16.667 16.9964 16.667 16.3333V7.16668C16.667 6.94567 16.7548 6.73371 16.9111 6.57743C17.0674 6.42114 17.2793 6.33335 17.5003 6.33335C17.7213 6.33335 17.9333 6.42114 18.0896 6.57743C18.2459 6.73371 18.3337 6.94567 18.3337 7.16668Z"
                              fill="#02102B"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_245_1666">
                              <rect
                                width="20"
                                height="20"
                                fill="white"
                                transform="translate(0 0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span>copy</span>
                      </button>
                      <button type="button" class="advice__regenerate">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_245_1668)">
                            <path
                              d="M10 1.6669C11.1054 1.67052 12.1992 1.89335 13.2179 2.32247C14.2366 2.75159 15.1602 3.37849 15.935 4.1669H13.3333C13.1123 4.1669 12.9004 4.25469 12.7441 4.41097C12.5878 4.56726 12.5 4.77922 12.5 5.00023C12.5 5.22124 12.5878 5.43321 12.7441 5.58949C12.9004 5.74577 13.1123 5.83356 13.3333 5.83356H16.7858C17.1962 5.83334 17.5897 5.67023 17.8798 5.38007C18.17 5.0899 18.3331 4.69642 18.3333 4.28606V0.833563C18.3333 0.61255 18.2455 0.400588 18.0893 0.244308C17.933 0.0880274 17.721 0.00022997 17.5 0.00022997C17.279 0.00022997 17.067 0.0880274 16.9107 0.244308C16.7545 0.400588 16.6667 0.61255 16.6667 0.833563V2.56523C15.2893 1.32477 13.5938 0.492238 11.7701 0.160854C9.94635 -0.17053 8.06636 0.0123233 6.34066 0.688936C4.61496 1.36555 3.11169 2.50921 1.99918 3.99183C0.886679 5.47446 0.208874 7.23752 0.0416727 9.08356C0.0309088 9.19961 0.0444358 9.31663 0.0813918 9.42716C0.118348 9.53769 0.177922 9.63931 0.25632 9.72555C0.334717 9.81179 0.430218 9.88075 0.536738 9.92804C0.643258 9.97533 0.75846 9.99992 0.875006 10.0002C1.07883 10.0028 1.27628 9.92926 1.42872 9.79393C1.58116 9.65861 1.6776 9.47126 1.69917 9.26856C1.88468 7.19419 2.83915 5.26415 4.37507 3.8576C5.911 2.45104 7.91735 1.66964 10 1.6669Z"
                              fill="#02102B"
                            />
                            <path
                              d="M19.1262 10.0001C18.9223 9.99747 18.7249 10.071 18.5724 10.2064C18.42 10.3417 18.3236 10.529 18.302 10.7317C18.1642 12.3178 17.5744 13.831 16.6025 15.0919C15.6306 16.3528 14.3175 17.3085 12.8188 17.8455C11.3201 18.3826 9.69882 18.4785 8.14728 18.1218C6.59575 17.7652 5.17907 16.971 4.06533 15.8334H6.66699C6.88801 15.8334 7.09997 15.7456 7.25625 15.5893C7.41253 15.4331 7.50033 15.2211 7.50033 15.0001C7.50033 14.7791 7.41253 14.5671 7.25625 14.4108C7.09997 14.2545 6.88801 14.1667 6.66699 14.1667H3.21449C3.01124 14.1666 2.80996 14.2066 2.62216 14.2843C2.43436 14.362 2.26372 14.476 2.12 14.6198C1.97628 14.7635 1.8623 14.9341 1.78457 15.1219C1.70684 15.3097 1.66688 15.511 1.66699 15.7142V19.1668C1.66699 19.3878 1.75479 19.5997 1.91107 19.756C2.06735 19.9123 2.27931 20.0001 2.50033 20.0001C2.72134 20.0001 2.9333 19.9123 3.08958 19.756C3.24586 19.5997 3.33366 19.3878 3.33366 19.1668V17.4351C4.71102 18.6755 6.4065 19.5081 8.23024 19.8395C10.054 20.1709 11.934 19.988 13.6597 19.3114C15.3854 18.6348 16.8886 17.4911 18.0011 16.0085C19.1137 14.5258 19.7915 12.7628 19.9587 10.9167C19.9694 10.8007 19.9559 10.6837 19.9189 10.5731C19.882 10.4626 19.8224 10.361 19.744 10.2747C19.6656 10.1885 19.5701 10.1195 19.4636 10.0723C19.3571 10.025 19.2419 10.0004 19.1253 10.0001H19.1262Z"
                              fill="#02102B"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_245_1668">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </div>`;
                }
              }

              // Закрывающие теги и кнопка возврата
              html += `
                    <a href="javascript:history.back()" class="advice__btn">Go back</a>
                  </div>
                </div>
              </div>
            </section>
            <div class="bg-element-top">
              <img src="https://stank.mungoweb.com/wp-content/themes/scimatch/img/fon.png" alt="" />
            </div>`;

              return html;
            }

            // Вставляем HTML в bodyWrapper
            if (bodyWrapper) {
              bodyWrapper.innerHTML = createHTML(data);
            }
            copyText();
            regeneration();
          })
          .catch((error) => {
            console.error(
              "There was a problem with the fetch operation:",
              error
            );
            alert("There was a problem, try again.");
          });
      }
      postJSON(formValues);
    });

    function render_progress_submit() {
      const bodyWrapper = document?.querySelector("body main");
      bodyWrapper.innerHTML = `
      <style>
        .overlay {
          display: none;
          width: 100%;
          height: 70vh;
          justify-content: center;
          align-items: center;
        }
        .progress-container {
          position: relative;
          z-index: 1;
          width: 250px;
          height: 250px;
        }
        
        .progress-bar {
          box-sizing: border-box;
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 8px solid  rgba(142, 177, 247, 0.3);
          border-top-color: #8eb1f7;
          animation: spin 1s linear infinite;
        }
        .label {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-family: Arial, sans-serif;
          font-size: 16px;
          color: #000;
          width: 200px;
          text-align: center;
        }
        
        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      </style> 
      <div class="overlay" id="overlay">
        <div class="progress-container">
          <div class="progress-bar" id="progress-bar"></div>
          <div class="label">Analyzing 🧐...</div>
        </div>
      </div>`;
      var overlay = document.getElementById("overlay");
      // var progress = document.getElementById("progress-bar");
      if (overlay) {
        overlay.style.display = "flex";
      }

      const label = document.querySelector(".label");
      const texts = [
        "AI-ing magic in progress 🪄...",
        "Polishing ...",
        "Adding brilliance to your intro 💎...",
        "It's worth the wait! 😎...",
        "Shaping your compelling start 💠... ",
      ];
      let currentIndex = 0;

      if (label) {
        setInterval(() => {
          label.textContent = texts[currentIndex];
          currentIndex = (currentIndex + 1) % texts.length;
        }, 5000);
      }
    }
  }
};
