export const animations = () => {
  function animationResult() {
    let counter = 1;

    const circle = document.querySelector(".result-progress__loader");

    function incrementCounter() {
      if (counter > 50) {
        clearInterval(intervalId); // Останавливаем таймер, если переменная больше 100
      } else {
        circle.style.background = `radial-gradient(closest-side,#f5f4ff 90%,transparent 91% 100%),conic-gradient(#00b327 ${counter}%, #CFD3E3 0%)`;
        counter += 1;
      }
    }

    let intervalId = setInterval(incrementCounter, 20);
  }
  if (document.querySelector(".result-progress__loader")) {
    animationResult();
  }

  function animationHero() {
    let counter = 1;
    const bar = document.querySelector(".hero-img__persent-text");

    // Начать incrementCounter() после задержки в 5.2 секунды
    setTimeout(() => {
      function incrementCounter() {
        if (counter > 100) {
          clearInterval(intervalId); // Останавливаем таймер, если переменная больше 100
        } else {
          bar.textContent = `${counter}%`;
          counter += 1;
        }
      }

      let intervalId = setInterval(incrementCounter, 20); // Вызываем функцию incrementCounter раз в 10 миллисекунд
    }, 2600);
  }

  if (document.querySelector(".hero-img__persent-text")) {
    animationHero();
  }
};
